<template>
  <div class="row">
    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="fullPage">
    </loading>
    <div class="col-12 col-md-12">
      <UserBar :analysts="analysts" :OPAnalyst="OPAnalyst" :analystProcessData="analystProcessData" :period="period" v-on:changePeriod="updatePeriod($event)" v-on:changeAnalyst="updateAnaLyst($event)"></UserBar>
    </div>
    <div class="row col-12 col-md-12">
      <div class="col-6 col-md-6 card" v-if="analystProcessData">
        <div class="card-body text-center py-3">
          <div class="row col-12 cold-sm-12 col-md-12 p-0">
            <div class="col-6 col-sm-6 col-md-6 p-0">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Confirmações de embarque hoje</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ analystsDataToday ? analystsDataToday.qtd : "0" }}
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 p-0 bl-1">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Confirmações de embarque no periodo</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ analystsDataPeriod ? analystsDataPeriod.qtd : "0" }}
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 col-sm-12 col-md-12 p-0 bt-1">
            <div class="p-0" v-bind:class="[analistBookingTime.length > 0 ? mdsz : fullsz]">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Processos no momento</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ analystProcessData ? analystProcessData.total : "0" }}
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 p-0 bl-1" v-if="analistBookingTime.length > 0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Media de tempo para confirmação de booking</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ getAnalistBookingMedia() }}
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 col-sm-12 col-md-12 p-0 bt-1">
            <div class="col-3 col-sm-3 col-md-3 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Aguardando</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ analystProcessData ? analystProcessData.aguardando : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 bl-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Efetivos</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ analystProcessData ? analystProcessData.efetivos : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 bl-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Embarcados</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ analystProcessData ? analystProcessData.embarcados : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 bl-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Clientes</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ analystProcessData ? analystProcessData.clientes : "0" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6 col-sm-6" v-if="analystsData.length > 0">
        <div class="card">
          <TripleBarChart :cardTitle="'Confirmações de embarque por dia'" :analystsData="analystsData"></TripleBarChart>
        </div>
      </div>
    </div>

    <!-- MEDIA SETOR -->
    <div class="row col-12 col-md-12">
      <div class="col-6 col-md-6 card" v-if="analystProcessData">
        <div class="card-body text-center py-3">
          <div class="row col-12 cold-sm-12 col-md-12 p-0">
            <h3 class="mb-0 text-center col-12 cold-sm-12 col-md-12 pb-2 bb-1">Média do setor: {{ analystProcessData ? analystProcessData.setor_analista : " " }}</h3>
            <div class="col-6 col-sm-6 col-md-6 p-0 br-1">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Confirmações de embarque hoje no setor hoje</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ teamStatisticsToday ? teamStatisticsToday.qtd : "0" }}
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 p-0">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Média de confirmações de embarque no periodo</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ teamDataPeriod ? teamDataPeriod.qtd : "0" }}
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 col-sm-12 col-md-12 p-0 bt-1">
            <div class="p-0" v-bind:class="[analistBookingTimeEquipe.length > 0 ? mdsz : fullsz]">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Média de processos no setor</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ teamStatistics ? teamStatistics.total : "0" }}
                </div>
              </div>
            </div>
            <div class="col-6 col-sm-6 col-md-6 p-0 bl-1" v-if="analistBookingTimeEquipe.length > 0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Media de tempo para confirmação de booking do setor</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ getAnalistTeamBookingMedia() }}
                </div>
              </div>
            </div>
          </div>
          <div class="row col-12 col-sm-12 col-md-12 p-0 bt-1">
            <div class="col-3 col-sm-3 col-md-3 br-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Aguardando</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ teamStatistics ? teamStatistics.aguardando : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 br-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Efetivos</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ teamStatistics ? teamStatistics.efetivos : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 br-1 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Embarcados</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ teamStatistics ? teamStatistics.embarcados : "0" }}
                </div>
              </div>
            </div>
            <div class="col-3 col-sm-3 col-md-3 p-0">
              <div class="d-flex mb-3 p-0">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Clientes</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center p-0">
                <div class="h1 mb-0 text-center p-0" >
                  {{ teamStatistics ? teamStatistics.clientes : "0" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6 col-sm-6 booking-box" v-if="analistBookingTime.length > 0">
        <div class="card p-3">
          <h5 class="box-title mb-0 text-center">Tempo até a confirmação de Booking</h5>
          <div class="row col-12">
            <div class="col-6 pb-3">
              <h5 class="box-title mb-0">Processo</h5>
            </div>
            <div class="col-6">
              <h5 class="box-title mb-0 cursor-pointer" @click="SortByTempo()">Tempo</h5>
            </div>
          </div>
          <div v-for="item in analistBookingTime" v-bind:key="item.processo + random()">
            <div class="row col-12 border-bottom py-1">
              <div class="col-6">
                <li class="list-unstyled">{{ item.processo }}</li>
              </div>
              <div class="col-6">
                <li class="list-unstyled">{{ item.dias }}d {{ item.horas }}h {{ item.minutos }}m</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12 col-md-12">
      <div class="col-6 col-md-6 col-sm-6 card" v-if="VGMEnviado.length > 0">
        <div class="col-12 col-md-12 col-sm-12">
          <TripleBarChart :analystsData="VGMEnviado" :cardTitle="'VGM enviados ao armador'" :barColor="['#268710']"></TripleBarChart>
          <div class="row bt-1">
            <div class="col-12 col-sm-12 col-md-12 p-0">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Total de envios</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ VGMEnviado.reduce((accumulator, object) => {return accumulator + object.qtd}, 0) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6 col-sm-6" v-if="draftenviado.length > 0">
        <div class="card">
          <TripleBarChart :analystsData="draftenviado" :cardTitle="'Drafts enviados ao armador'" :barColor="['#872a10']"></TripleBarChart>
          <div class="row bt-1">
            <div class="col-12 col-sm-12 col-md-12 p-0">
              <div class="d-flex mb-3">
                <div class="col-12 cold-sm-12 col-md-12 p-0">
                  <h5 class="box-title mb-0 text-center">Total de envios</h5>
                </div>
              </div>
              <div class="col-12 cold-sm-12 col-md-12 mb-2 text-center">
                <div class="h1 mb-0 text-center p-0">
                  {{ draftenviado.reduce((accumulator, object) => {return accumulator + object.qtd}, 0) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line
import KpiService from '@/services/KPI/KpiService'

// Loading
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import UserBar from '@/components/Kpi/UserBar.vue'
import TripleBarChart from '@/components/Kpi/charts/TripleBarChart.vue'
import _ from 'lodash'

export default {
  name: 'OperationalAnalyst',
  metaInfo () {
    return {
      titleTemplate: this.$i18n.t('kpi.title') + ' ' + this.$i18n.t('kpi.analyst.title') + ' - %s'
    }
  },
  data () {
    return {
      OPAnalyst: false,
      isLoading: false,
      fullPage: true,
      anaLyst: [],
      analysts: [],
      analystsDataToday: '0',
      analystsDataPeriod: '0',
      analystProcessData: [],
      analystsData: [],
      analistBookingTime: [],
      analistBookingTimeEquipe: [],
      VGMEnviado: [],
      draftenviado: [],
      period: '',
      TempoSorting: true,
      teamStatistics: [],
      teamStatisticsToday: [],
      teamDataPeriod: [],
      fullsz: 'col-12 col-sm-12 col-md-12',
      mdsz: 'col-6 col-sm-6 col-md-6'
    }
  },
  components: {
    Loading,
    UserBar,
    TripleBarChart
  },
  beforeMount () {

  },
  mounted () {
    this.getAnalystList()
    setTimeout(() => {
      this.isOperationalAnalyst()
    }, 3000)
  },
  methods: {
    SortByTempo () {
      if (this.TempoSorting === true) {
        this.analistBookingTime.sort((a, b) => a.tempoEmMin < b.tempoEmMin ? 1 : -1)
        this.TempoSorting = false
      } else {
        this.analistBookingTime.sort((a, b) => a.tempoEmMin > b.tempoEmMin ? 1 : -1)
        this.TempoSorting = true
      }
    },
    random () {
      return _.random(1000)
    },
    getAnalistBookingMedia () {
      let timeArray = []
      let va = 0
      let sumVa
      let totalVal
      if (this.analistBookingTime.length > 0) {
        this.analistBookingTime.forEach((value, index) => {
          timeArray.push(this.analistBookingTime[index]['tempoEmMin'])
        })
        va = 0
        sumVa = timeArray.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          va
        )
      }
      totalVal = Math.floor(sumVa / timeArray.length)
      totalVal = this.secondsToDhms(totalVal)
      return totalVal
    },
    getAnalistTeamBookingMedia () {
      let timeArray = []
      let va = 0
      let sumVa
      let totalVal
      if (this.analistBookingTimeEquipe.length > 0) {
        this.analistBookingTimeEquipe.forEach((value, index) => {
          timeArray.push(this.analistBookingTimeEquipe[index]['tempoEmMin'])
        })
        va = 0
        sumVa = timeArray.reduce(
          (previousValue, currentValue) => previousValue + currentValue,
          va
        )
      }
      totalVal = Math.floor(sumVa / timeArray.length)
      totalVal = this.secondsToDhms(totalVal)
      return totalVal
    },
    secondsToDhms (minutes) {
      let seconds = minutes * 60
      seconds = Number(seconds)
      let d = Math.floor(seconds / (3600 * 24))
      let h = Math.floor(seconds % (3600 * 24) / 3600)
      let m = Math.floor(seconds % 3600 / 60)

      let dDisplay = d > 0 ? d + 'd' : 0 + 'd'
      let hDisplay = h > 0 ? h + 'h' : 0 + 'h'
      let mDisplay = m > 0 ? m + 'm' : 0 + 'm'
      return dDisplay + ' ' + hDisplay + ' ' + mDisplay
    },
    isOperationalAnalyst () {
      let userInfo = this.$store.getters.userInfo || null
      this.anaLyst = { 'idresponsavel': userInfo.id, 'nome_analista': userInfo.full_name }

      if (userInfo && userInfo.roles.indexOf('ROLE_OPERATIONAL_LEADER') !== -1) {
        this.OPAnalyst = true
      } else {
        this.OPAnalyst = false
      }
    },
    updateAnaLyst (anaLystUpdated) {
      this.anaLyst = anaLystUpdated
    },
    updatePeriod (periodUpdated) {
      this.period = periodUpdated
    },
    getAnalystList () {
      this.isLoading = true

      KpiService.getAnalysts().then(res => {
        this.analysts = res.data.data
        this.isLoading = false
      })
    },
    getAnalystData (val, period) {
      this.isLoading = true
      let analystId = val.idresponsavel

      KpiService.getAnalystsData(analystId, period).then(response => {
        this.analystsData = response.data.data
        this.analystsDataToday = response.data.today[0]
        this.analystsDataPeriod = response.data.period[0]
        this.analystProcessData = response.data.processData[0]
        this.analistBookingTime = response.data.bookingtime
        this.analistBookingTimeEquipe = response.data.bookingtimeEquipe
        this.VGMEnviado = response.data.vgmenviado
        this.draftenviado = response.data.draftenviado
        this.isLoading = false
      })
    },
    getTeamStatistics (val, period) {
      this.isLoading = true
      if (val && val.id_setor) {
        let setorId = val.id_setor

        KpiService.getTeamStatistics(setorId, period).then(response => {
          this.teamStatistics = response.data.data[0]
          this.teamStatisticsToday = response.data.today[0]
          this.teamDataPeriod = response.data.period[0]
          this.isLoading = false
        })
      }
      this.isLoading = false
    }
  },
  computed: {

  },
  watch: {
    anaLyst: {
      handler (val) {
        let periodF = this.period
        this.getAnalystData(val, periodF)
      },
      deep: true
    },
    period: {
      handler (val) {
        if (this.anaLyst.length !== 0) {
          let anaLyst = this.anaLyst
          this.getAnalystData(anaLyst, val)
        }
      },
      deep: true
    },
    analystProcessData: {
      handler (val) {
        let period = this.period
        this.getTeamStatistics(val, period)
      }
    }
  }
}
</script>

<style scoped>
  .text-success {
    color: #3c763d;
  }
  .h1 {
    font-size: 2.2rem;
  }
  .bt-1 {
    border-top: 1px solid #cecece;
  }
  .bb-1 {
    border-bottom: 1px solid #cecece;
  }
  .br-1 {
    border-right: 1px solid #cecece;
  }
  .bl-1 {
    border-left: 1px solid #cecece;
  }
  .desc-txt {
    font-size: 12px; line-height: 14px; margin-bottom: 6px;
  }
  .booking-box {
    max-height: 39vh;
    overflow: scroll;
  }
  .cursor-pointer {
    cursor: pointer;
  }
</style>
