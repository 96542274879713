<template>
  <div class="p-3">
    <h5 class="box-title mb-0 text-center">{{ cardTitle }}</h5>
    <div id="chart">
      <VueApexCharts type="bar" height="250" :cardTitle="cardTitle" :options="chartOptions" :series="series" :key="componentKey"></VueApexCharts>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'TripleBarChart',
  components: {
    VueApexCharts
  },
  data () {
    return {
      componentKey: 0,
      days: [],
      values: [],
      graphData: [],
      series: [{
        name: '',
        data: [],
        strokeColor: '#bd37bf'
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          width: '100%'
        },
        responsive: [
          {
            breakpoint: 1000,
            options: {
              plotOptions: {
                bar: {
                  horizontal: false
                }
              },
              legend: {
                position: 'bottom'
              }
            }
          }
        ],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded',
            dataLabels: {
              position: 'top'
            }
          }
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ['#304758']
          }
        },
        yaxis: {
          title: {
            text: ''
          }
        },
        fill: {
          colors: [],
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1
          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
    }
  },
  props: {
    analystsData: {
      type: Array
    },
    barColor: {
      type: Array
    },
    cardTitle: {
      type: String
    }
  },
  mounted () {

  },
  watch: {
    analystsData: {
      handler (val) {
        this.series = [{
          data: this.analystsData.map((item) => {
            return item.qtd
          })
        }]

        this.days = this.analystsData.map((item) => {
          return item.data_conclusao
        })

        this.chartOptions = { ...this.chartOptions,
          ...{
            xaxis: {
              categories: this.days
            },
            fill: {
              colors: this.barColor
            }
          }
        }
      },
      deep: true
    }
  }
}
</script>

<style>

</style>
